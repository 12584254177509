.mySelectDefault .cancelContainer {
  display: none;
  margin: 0.5em 0.5em 0.5em 0.5em;
}
.mySelectDefault.valueSelected .cancelContainer {
  display: inline-block;
}
.cancelContainer {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 20px;

}
.cancelContainer:hover .cancelContent {
  color: lightgray;
}
.cancelContainer:active .cancelContent {
  color: lightblue;
}
.cancelContent {
  display: inline-block;
  height: 20px;
}

.cancelContent svg {
  fill: #aaa;
  transition: fill 0.15s ease;
}
.cancelContent svg:hover {
  fill: darkslategray;
}
