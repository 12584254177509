.mainSectionWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.selectedDisplay {
  display: inline-block;
  position: relative;
  margin: 0.5em 0 0.5em 0.5em;
  text-align: left;
  vertical-align: middle;
}
.mySelectDefault.fixedHeight .selectedDisplay {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
