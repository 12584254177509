.mySelectDefault .downArrowContainer {
  height: 20px;
  margin-left: auto;
  cursor: pointer;
  margin-right: 0.5em;
}
.downArrowContent svg {
  fill: #aaa;
  transition: fill 0.15s ease;
}
.downArrowContent svg:hover {
  fill: darkslategray;
}
